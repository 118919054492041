<div class="bg-cards" *ngFor="let data of cards;let i = index">
    <!-- <div class="card-img-align" (mouseover)="hovered = i" (mouseout)="hovered = -1" [ngStyle]="{'transform':(i == hovered)?alignHoverCard(): 'translateX(0px)'}"> -->
    <div class="card-img-align">
        <div class="card-grad" (click)="redirect(data)">
            <img *ngIf="type !== 'genreList'" class="card-img" loading="lazy" src="{{data.banner_low ? (settingsService.imagePath + data.banner_low) : 'assets/' + appName + '/img/fallback-movies.svg'}}">
            <img *ngIf="type === 'genreList'"  class="card-img" loading="lazy" src="{{settingsService.imagePath + data.logo}}">
        </div>
        <div (click)="redirect(data)" [ngClass]="type === 'genreList' ? 'genre-img-gradient' : 'img-gradient'"></div>
        <div (click)="redirect(data)" *ngIf="type === 'genreList'" class="video-detail">
            <p>{{data.title}}</p>
            <a> {{configService?.localeKeys?.genre_card_view_content_desc || 'View Content'}} > </a>
        </div>
        <div *ngIf="type !== 'genreList'" class="meta-card">
            <div *ngIf="data?.publish_time" class="publish-card ">{{data?.publish_time}}</div>
            <span *ngIf="data?.publish_time && data?.age " class="pipe">|</span>
            <span *ngIf="data?.genre && genreCurationEnabled == 1">{{configService?.localeKeys[data?.genre?.split(",")[0]]}}</span>
            <span class="pipe" *ngIf="data?.genre && data?.genre !== '' && genreCurationEnabled == 1 && data?.tags && data?.tags.length !== 0">|</span>
            <span *ngIf="data?.tags && genreCurationEnabled == 0">{{data?.tags[0]}}</span>
            <span *ngIf="data?.is_series == 1">
                <span class="pipe"  *ngIf="data?.total_seasons">|</span>
                <span class="text-style" *ngIf="data?.total_seasons">{{data?.total_seasons}} {{configService.localeKeys?.kws_kw_sesons_def}}</span>
            </span>
            <span *ngIf="data?.is_series == 0" class="tags-section d-flex">
                <span class="pipe" *ngIf="data?.duration_min">|</span>
                <span *ngIf="data?.duration_min" class ="text-style">{{data?.duration_min | movieDuration}}</span>
            </span>
        </div>
        <div *ngIf="type !== 'genreList'" class="actions-card">
            <span class="actions-card-container">
                <span class = "action-card-tooltip" *ngIf="!(baseService.guest | async)" >
                    <img (click)="dialog.closeAll();playContentService.playVideo(data,'play')" class="cursor-pointer card-icon" src="assets/{{appName}}/img/card-play.svg">
                    <span class = "action-card-tooltip-text">{{configService?.localeKeys?.player_play_video || 'Play'}}</span>
                </span>
                <span class = "action-card-tooltip"*ngIf="!data?.wishlist && !(baseService.guest | async) && !data?.is_livechannel && !restrictedUser">
                    <img (click)="addToWishlist(data)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_add.svg">
                    <span class = "action-card-tooltip-text">{{configService?.localeKeys?.home_pop_menu_wishlist_add || 'Add to wishlist'}}</span>
                </span>
                <span class = "action-card-tooltip" *ngIf="data?.wishlist && !(baseService.guest | async) && !data?.is_livechannel && !restrictedUser">
                    <img (click)="removeFromWishlist(data)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_remove.svg">
                    <span class = "action-card-tooltip-text">{{configService?.localeKeys?.remove_watchlist_definition || 'Remove from watchlist'}}</span>
                </span>
                <span class = "action-card-tooltip">
                    <img (click)="redirect(data)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/content_info.svg">
                    <span class = "action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                </span>
            </span>
        </div>
    </div>
    <div class="title" *ngIf="type === 'genreList'" id="details">
        <div id="head">
            <div id="title" class="text-hidden">{{data.title}}</div>
        </div>
    </div>
    <div *ngIf="type !== 'genreList'" id="details">
        <div id="head">
            <div id="title" class="text-hidden">{{data.title}}</div>
            <div id="duration" *ngIf="data.duration">
                <div id="time">{{data?.duration_min | movieDuration}}</div>
                <img id="clock" src="assets/{{appName}}/img/duration.svg">
            </div>
        </div>
        <div id="footer">
            <div id="description" class="text-vertical-hidden">
                {{data.description}}
            </div>
        </div>
    </div>
</div>
