import { Injectable, OnInit } from '@angular/core';
import { BaseService, SettingsService, StorageService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { refreshToken, login, Register, logout, userSettings, hooplaProcess } from 'src/app/shared/constants';
import { UikitService } from '../services/uikit.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatalayerService } from '../services/datalayer.service';
import { Location } from '@angular/common';
import { ConfigurationService } from '../services/configuration.service';
import { SubscriptionService } from '../services/subscription.service';
import { BULSATCODE, COUPON, VODAFONE_ROMANIA, VOUCHER } from 'src/app/shared/constants/key.constants';
import { VOUCHER_SIGNIN } from 'src/app/shared/constants/local.constants';
import { R_VOUCHER_SIGNIN } from 'src/app/shared/constants/route.constants';
import { environment } from 'src/environments/environment';
import { LoadService } from '../services/load.service';
import { CookieService } from 'ngx-cookie';
import * as $ from 'jquery'
import { PaymentService } from '../services/payment.service';
import { OperatorService } from '../services/operator.service';
import { catchError, tap, map } from 'rxjs/operators';
import { VoucherService } from '../services/voucher.service';
import * as UAParser from 'ua-parser-js';
import { PlanService } from '../services/plan.service';
declare global {  interface Window {dengage: any; } }

@Injectable({
  providedIn: 'root'
})
export class LoggerService  {
  memorySource = new BehaviorSubject<any>({});
  memoryStorage = this.memorySource.asObservable();
  hooplaUser = new BehaviorSubject(false);
  hooplaUserObj = this.hooplaUser.asObservable();
  reset = false;
  isSVOD = 0;
  user_settings;
  nRegion;
  oldRegion;
  nCountryCode;
  voucherAccessToken = '';
  public activeVoucherErrorMsg = new BehaviorSubject('');
  currentOperator: any;
  operator:any
  BulsatData: any;
  oldAccessToken;
  newAccessToken;
  newUserId;
  loginPostData;
  loginResponse;
  browserInfo: any;
  usertype: any;
  isVoucherSuspended: any;
  userTelco: any;
  paymentMethods: any;
  cardAvail: boolean;
  idealAvail: boolean;

  constructor(private http: HttpClient, private baseService: BaseService, private route: ActivatedRoute,
              private router: Router, private storageService: StorageService, private uikitService: UikitService,
              private datalayerService: DatalayerService, private location: Location,
              private configService: ConfigurationService, private subscriptionService: SubscriptionService,
              private settingsService: SettingsService, private loadService:LoadService,
              private cookieService: CookieService, private paymentService: PaymentService,
              private voucherService:VoucherService, private planService: PlanService) {
    const sessionStorage = this.storageService.getLocalStore('sessionStorage');
    this.memorySource.next(sessionStorage);
    window.dataLayer = window.dataLayer || [];
    this.oldAccessToken = this.storageService.getLocalStore('a_t');
    let value = new UAParser();
    let parser = value.getResult();
    let device = "Browser (Desktop)"
    if(window.innerWidth <= 768) device = "Browser (Mobile)"
    this.browserInfo =  {"manufacturer": device, "model": parser.browser.name,"version": parser.browser.version,
    "osName": parser.os.name, "osVersion": parser.os.version ,"displayWidth":  window.innerWidth, "displayHeight": window.innerHeight}
  }

  updatedMemoryStorage(data) {
    this.memorySource.next(data);
  }

  loginCheck(data):Observable<any>{
    data['user_device_info'] = this.browserInfo;
    const loginAPI: string = environment.apiUrl + environment.version + login;
    return this.http.post(loginAPI, data).pipe(
      map((response:any)=>{
        if(response.status_code === 200){
          this.baseService.signLoaderSource.next(false)
          return response;
      }
      }), catchError((error)=>{
        this.baseService.signinCounterErrorFn();
        if(data.login_by == "manual"){
          data.login_by = "Email";
        }
        const datalayerData = {
          event: 'GAEvent',
          eventCategory: 'Membership Actions',
          eventAction: 'Login',
          eventLabel: 'Error -' + data.login_by + '/Username',
          loginStatus: 'False',
          userId: '',
          notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
          region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
          platform: this.baseService.browserType,
          userType: this.storageService.getLocalStore('user_type'),
          operatorName: this.storageService.getLocalStore('operator_name')
        };
        this.datalayerService.addDatalayer(datalayerData);
        this.baseService.signLoaderSource.next(false);
        this.uikitService.staticErrorMessage(error.error.error.message);
        return error
      })
    )
  }

  loginFnEzproxy(data) {
    const loginAPI: string = environment.apiUrl + environment.version + login;
    return new Promise((resolve) => {
      return this.http.post<any>(loginAPI, data).subscribe(
        (response) => {
          if (response.status_code === 200) {
            this.setAuthData(response);
          } else {
            this.gaEventErrorEzproxy(data);
          }
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          this.gaEventErrorEzproxy(data);
          const errorMsg = error?.error?.error?.message;
          this.uikitService.staticErrorMessage(errorMsg);
          resolve(error);
        }
      );
    });
  }

  gaEventErrorEzproxy(data) {
    const datalayerData = {
      event: 'GAEvent',
      eventCategory: 'Membership Actions',
      eventAction: 'Login',
      eventLabel: 'Error -' + data.login_by + '/Username'
    };
    this.datalayerService.addDatalayer(datalayerData);
  }

  async loginFn(data) {
    let response = this.loginResponse;
    this.clearVariable();
        if (response.status_code === 200) {
          this.baseService.signLoaderSource.next(false)
          const respData = response.data;
          const discountType = this.storageService.getLocalStore('discount_type');
          const discountCode = this.storageService.getLocalStore('discount_code');
          const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
          // After login we need to know user is subscribed or not to display choose plan strip in home page. So storing the values (We need to store before navigating to home page)
          this.isVoucherSuspended = respData?.voucher?.is_voucher_suspended;
          this.userTelco = respData?.user_telco;
          this.subscriptionService.userType = respData.user_type || 0;
          document.cookie = 'd_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'g_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'hoopla_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'universal_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; 
          document.cookie = 'hoopla_transaction_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'hoopla_start_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'hoopla_end_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          let couponResp;
          if (respData?.user_type !== 0 && ((operatorVoucher && discountCode && discountType === VOUCHER) || (discountCode && discountType === VOUCHER) || (discountCode && (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)))) {
            this.voucherAccessToken = respData.access_token;
            if (discountType === VOUCHER) {
              const Coupendata = { voucher_code: discountCode, d_type: 'web' };
              couponResp = await this.subscriptionService.applyCoupon(Coupendata);
            } else if (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA) {
              const coupenData: any = {
                code: discountCode,
                d_type: 'web',
                operator_reference_number: this.storageService.getLocalStore('operatorReference'),
                customer_identifier_type: this.storageService.getLocalStore('customerIdentifierType'),
              };
              coupenData['operator'] = this.baseService.telcoSlug;
              couponResp = await this.voucherService.bulsatSubcription(coupenData);
            }
            if (couponResp?.error && couponResp.error?.code !== 1002) {
              this.voucherAccessToken = '';
              this.activeVoucherErrorMsg.next(couponResp.error.error?.message);
              return;
            }
          }
          if(respData.user_login_by == "manual"){
            respData.user_login_by = "Email";
          }
          const uId = respData.user_id;
          if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', uId);
          const dId: string = this.storageService.getLocalStore('d_id');
          const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
          $('body').append(bodyScript);
          let userType = 'Free User';
          let operator_name = '';
          this.storageService.setLocalStore('a_t', respData.access_token);
          this.storageService.setLocalStore('r_t', respData.refresh_token);
          this.storageService.setLocalStore('u_id', uId);
          this.storageService.setLocalStore('logged_in', respData.logged_in);
          if(respData.user_email_notified == 1){
            localStorage.setItem('emailNotify','True');
          }
          if(respData.user_email_notified != 1){
            localStorage.setItem('emailNotify','False');
          }
          let cookieuName = 'u_id';
          let cookieuValue = uId;
          document.cookie = cookieuName + "=" + cookieuValue + ";domain="+environment.cookieDomain+";path=/";
          const updateMemoryData = {
            login_by: respData.user_login_by,
            user_picture: respData.user_picture,
            user_name: respData.user_name,
            user_type: respData.user_type,
            user_telco: respData.user_telco,
            is_voucher_applied: respData?.is_voucher_applied,
            partner_name: respData?.voucher?.partner_name,
            partner_description: respData?.voucher?.partner_description,
            one_time_subscription: respData.one_time_subscription,
            u_id: uId
          };
          this.paymentService.paymentMethodLength = respData.stripe_payment_method.length;
          if (respData.user_telco) {
            userType = 'Operator User';
            operator_name = respData.user_telco;
          } else if (respData.is_voucher_applied && respData.is_voucher_applied == 1) {
            userType = 'Voucher User';
            operator_name = respData.voucher.partner_name + '-' + respData.voucher.partner_description;
          } else if (respData.user_type == 1) {
            userType = 'Paid User';
          }
          this.baseService.showCardData.next({ no_past_due: true, session_id: undefined })
          this.storageService.setLocalStore('user_type', userType);
          this.storageService.setLocalStore('operator_name', operator_name);
          this.memorySource.next(updateMemoryData);
          this.storageService.setLocalStore('sessionStorage', updateMemoryData);
          this.storageService.setLocalStore('event_user_status', respData.user_type);
          this.baseService.guestSource.next(false);
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Membership Actions',
            eventAction: 'Login',
            eventLabel: 'Success - ' + respData.user_login_by,
            loginStatus: 'True',
            userId: uId,
            notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
            region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operatorName: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
          // if (respData.user_telco !== '') {
          //   let loginSucessMsg = this.configService.localeKeys?.login_msg_login_succ_def;
          //   if (this.router.url !== R_VOUCHER_SIGNIN) {
          //     this.uikitService.staticSuccessMessage(loginSucessMsg);
          //   }
          // }
          const authenticate = this.storageService.getLocalStore('authenticate');
          const contentURL = this.storageService.getLocalStore('contentURL');
          const queryParamTv = this.route.snapshot.queryParams['app'];
          if (queryParamTv === this.baseService.keyQueryParamTV || queryParamTv === this.baseService.keyQueryParamAppleTV){
            if(this.settingsService.activateYourTvFromTVCode){
              this.router.navigateByUrl('account/activate-tv');
            };
            if(this.settingsService.activateYourTvFromWebCode){
              this.router.navigateByUrl('account/authenticate');
            };
          } else if ((respData.is_voucher_applied === "1" || respData.user_type === 1) && this.router.url === R_VOUCHER_SIGNIN) {
            if (respData.is_voucher_applied === "1") {
              this.uikitService.staticErrorMessage(this.configService.localeKeys?.already_active_voucher || 'You have an active voucher already');
            } else if (respData.user_type === 1) {
              this.uikitService.staticErrorMessage(this.configService.localeKeys?.pay_fail_title_duplicate || 'You already have an active subscription!');
            }
            this.router.navigateByUrl('/home')
          } else if (discountCode && (discountType === VOUCHER || discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)) {
            let couponResp: any;
            if (discountType === VOUCHER) {
              const Coupendata = { voucher_code: discountCode, d_type: 'web' };
              couponResp = await this.subscriptionService.applyCoupon(Coupendata);
            } else if (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA) {
              const Coupendata = {
                code: discountCode, d_type: 'web',
                operator_reference_number: this.storageService.getLocalStore('operatorReference'),
                customer_identifier_type: this.storageService.getLocalStore('customerIdentifierType'),
              };
              couponResp = await this.voucherService.bulsatSubcription(Coupendata);
            }
            if (couponResp.status_code === 200) {
              this.storageService.setLocalStore(VOUCHER_SIGNIN, '1');
              const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
              if (operatorVoucher) {
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { step: '5', auth: null },
                  queryParamsHandling: 'merge'
                })
              } else {
                this.router.navigateByUrl('/voucher/success');
              };
            } else if (couponResp.error && couponResp.error.code !== 1002) {
                this.voucherAccessToken = '';
                this.activeVoucherErrorMsg.next(couponResp.error.error?.message);
                this.removeLogoutData();
                return;
            }
            this.storageService.removeLocalStore('discount_code');
            this.storageService.removeLocalStore('discount_type');
            this.storageService.removeLocalStore('operator_voucher');
          } else if (discountCode && discountType === COUPON) {
            this.storageService.setLocalStore(VOUCHER_SIGNIN, '1');
            const selectedPlanData = this.storageService.getSessionStore('selectedPlanData');
            this.proceedPayment(selectedPlanData);
          } else if (authenticate) {
            this.storageService.removeLocalStore('authenticate');
            if(this.settingsService.activateYourTvFromTVCode){
                this.router.navigateByUrl('account/activate-tv');
            };
            if(this.settingsService.activateYourTvFromWebCode){
                this.router.navigateByUrl('account/authenticate');
            };
          } else if (contentURL) {
            this.storageService.removeLocalStore('contentURL');
            location.href = contentURL;
          } else {
            this.router.navigateByUrl('/home')
          }
          await this.getUserData();
          this.storageService.removeLocalStore('authErrorCounter');
          this.baseService.signinCaptchaValidation.next(0);
        } else {
          this.baseService.signinCounterErrorFn();
          if(data.login_by == "manual"){
            data.login_by = "Email";
          }
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Membership Actions',
            eventAction: 'Login',
            eventLabel: 'Error -' + data.login_by + '/Username',
            loginStatus: 'False',
            userId: '',
            notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
            region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operatorName: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
        };
  }

  getUserSettings:any = async() => {
    let settingsUrl = environment.apiUrl + environment.v3_version + userSettings;
    let  userId  = this.storageService.getLocalStore('u_id');
    let  deviceId = this.storageService.getLocalStore('d_id');
    let localeSetting = this.storageService.getLocalStore('locale')
    let logged_in:any = 1;
    let params = new HttpParams()
    .set('d_type', 'web')
    .set('logged_in', logged_in)
    .set('locale', localeSetting);
    let paramurl =  '/' + deviceId + '/' + userId;
    settingsUrl = settingsUrl + paramurl;
    return new Promise((resolve, reject) => {
      return this.http.post<any>(settingsUrl, {}, { params: params })
        .subscribe(
          async (response) => {
            if (response.status_code === 200) {
              const respData = response.data;
              this.usertype = respData.user.user_type;
              this.isVoucherSuspended = respData.user?.voucher?.is_voucher_suspended;
              this.userTelco = respData?.user?.user_telco;
              this.subscriptionService.userType = respData.user.user_type || 0;
              this.subscriptionService.userDetails = {name: respData.user?.user_name, email: respData.user?.user_email};
              return resolve(respData);
            }
            else {
              this.uikitService.notifyError(response.error);
              return resolve(response.error);
            }
          },
          (error) => {
            return resolve(error);
          }
        );
    });
  }
  async getUserData(){
    this.user_settings = await this.getUserSettings();
    this.nCountryCode = this.user_settings.configuration['country_code'];
    this.nRegion = this.user_settings.configuration.region['region_code'];
    this.oldRegion = this.storageService.getLocalStore('region');
    if(this.nRegion != this.oldRegion) await this.setNewRegion();
  }
  setNewRegion(){
    return new Promise<boolean>((resolve, reject) => {
      this.storageService.setLocalStore('country_code',this.nCountryCode);
      this.storageService.setLocalStore('region',this.nRegion);
      const pathNameArr = location.pathname.split('/');
      const regionPath = pathNameArr[1];
      location.pathname = location.pathname.replace(regionPath, this.nRegion);
      resolve(true);
    })
  }

  signUpFn(data, selectedPlan?) {
    let email_notify = 'False';
    if(data.email_notification == 1) {
       email_notify = 'True';
       localStorage.setItem('emailNotify','True');
    }
    if(data.email_notification != 1) localStorage.setItem('emailNotify','False');
    const queryparams  = this.route.snapshot.queryParams
    if(this.baseService.telcoSlug && queryparams?.auth === 'signup') data['operator'] = this.baseService.telcoSlug;
    this.isSVOD = this.settingsService.isSvodEnabled;
    const signUpAPI = environment.apiUrl + environment.version + Register;
    const region = this.storageService.getLocalStore('region');
    data['unique_device_id'] = this.storageService.getLocalStore('uniq_id');
    data['user_device_info'] = this.browserInfo;
      return new Promise((resolve, reject) => {
        this.http.post<any>(signUpAPI, data).subscribe(
          async (response: any) => {
            if (response.status_code === 200) {
              this.baseService.signLoaderSource.next(false);
              this.paymentMethods = response?.data?.stripe_payment_method;
              this.paymentService.paymentMethodLength = this.paymentMethods.length;
              document.cookie = 'd_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'g_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'hoopla_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'universal_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; 
              document.cookie = 'hoopla_transaction_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'hoopla_start_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'hoopla_end_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              if(this.settingsService.dEngageFlag && window.dengage){
                window.dengage('setContactKey', response.data.user_id);
              }
              if (response.data.verifyEmail === 1) {
                // this.storageService.setLocalStore('mail', data.email);
                // this.uikitService.notifySuccess(response);
                this.storageService.setLocalStore('cu_id', response.data.user_id);
                this.router.navigateByUrl('/auth/verify');
                resolve(response);
              } else {
                this.signupFlow(response.data, email_notify, selectedPlan);
                resolve(response);
              }
            } else {
              // this.baseService.counterErrorFn();
              const datalayerData = {
                event: 'GAEvent',
                eventCategory: 'Membership Actions',
                eventAction: 'Register',
                eventLabel: 'Error',
                loginStatus: 'False',
                userId: '',
                notificationStatus: email_notify,
                region: (region ? region : ''),
                platform: this.baseService.browserType,
                userType: this.storageService.getLocalStore('user_type'),
                operator: this.storageService.getLocalStore('operator_name')
              };
              this.datalayerService.addDatalayer(datalayerData);
              this.baseService.signLoaderSource.next(false)
              resolve(response);
            }
          },(error: HttpErrorResponse) => {
              // this.baseService.counterErrorFn();
              if (error?.error?.error?.code !== 1023) {
                this.uikitService.staticErrorMessage(error?.error?.error?.message);
              }
              const datalayerData = {
                event: 'GAEvent',
                eventCategory: 'Membership Actions',
                eventAction: 'Register',
                eventLabel: 'Error' ,
                loginStatus: 'False',
                userId: '',
                notificationStatus: email_notify,
                region: (region ? region : ''),
                platform: this.baseService.browserType,
                userType: this.storageService.getLocalStore('user_type'),
                operator: this.storageService.getLocalStore('operator_name')
              };
              this.datalayerService.addDatalayer(datalayerData);
              this.baseService.signLoaderSource.next(false)
              resolve(error)
          }
        );
      });
  }

  async proceedPayment(selectedPlan) {
    this.storageService.setSessionStore('selectedPlanData', selectedPlan);
    if (selectedPlan.free_plan === 1) {
      const reqObj = {
        d_type: 'web',
        subscription_id: selectedPlan.subscription_id,
      };
      this.storageService.setLocalStore('subsId', selectedPlan.subscription_id);
      await this.subscriptionService.getFreePlanSubscription(reqObj).subscribe(res => {
        if (res) this.nextStepConditions(res, selectedPlan);
      });
    } else {
      this.nonFreePlanPayment(selectedPlan);
    }
  }

  nonFreePlanPayment(selectedPlan) {
    this.baseService.disableFormBtnFn();
    // storing selected plan data as it is needed in other places like card details, signup page
    this.storageService.setSessionStore('selectedPlanData', selectedPlan);
    const postData = {
      d_type: 'web',
      u_id: this.storageService.getLocalStore('u_id'),
      subscription_id: selectedPlan.subscription_id,
      stripe_plan_id: selectedPlan.stripe_plan_id,
      pricing_plan_id: selectedPlan.pricing_plan_id,
    };
    this.storageService.setLocalStore('subsId', selectedPlan.subscription_id);
    this.selectedPlanGTM(selectedPlan?.plan);
    this.subscriptionService.checkOut(postData).subscribe((data) => {
      if (data) {
        this.nextStepConditions(data, selectedPlan);
      }
    }, (error) => {
      if (error?.error?.error?.code !== 1002) {
        this.uikitService.notifyError(error);
      }
      if (error?.error?.error?.code === 2192) {
        this.router.navigateByUrl('/home')
      }
    });
  }

  selectedPlanGTM(plan:string): void {
    const uId: string = this.storageService.getLocalStore('u_id');
    const region: string = this.storageService.getLocalStore('region');
    const datalayerData = {
      event: 'GAEvent',
      eventCategory: 'Payment',
      eventAction: 'Plan Selected',
      eventLabel: plan,
      platform: this.baseService.browserType,
      loginStatus: 'True',
      userId: (uId ? uId : ''),
      notificationStatus: localStorage.getItem('emailNotify') ||  'False',
      region: ( region? region : ''),
      userType: this.storageService.getLocalStore('user_type'),
      operator: this.storageService.getLocalStore('operator_name')
    };
    this.datalayerService.addDatalayer(datalayerData);
  }

  nextStepConditions(data, selectedPlan) {
    const couponMapped = selectedPlan.coupon_mapped || 0;
    const session_id = data.session_id;
    let queryParams = { session_id };
    const discountCoupon = this.storageService.getLocalStore('discount_code');
    const discountType = this.storageService.getLocalStore('discount_type');

    if (selectedPlan.free_plan === 1) {
      this.router.navigate(['auth/welcome']);
    } else if (discountType === COUPON && discountCoupon && !selectedPlan?.free_plan) {
      this.router.navigate(['/voucher/method', couponMapped], { queryParams });
    } else {
      const keyQueryParamTV = this.route.snapshot.queryParams['app'];
      if(keyQueryParamTV === this.baseService.keyQueryParamTV){
        queryParams['app'] = this.baseService.keyQueryParamTV;
      } else if (keyQueryParamTV === this.baseService.keyQueryParamAppleTV) {
        queryParams['app'] = this.baseService.keyQueryParamAppleTV;
      }
      const navigatinUrl = this.paymentService.paymentMethodLength > 1 ? '/auth/stripe/method' : '/auth/stripe/payment';
      this.router.navigate([navigatinUrl], { queryParams });
    }
  }

  async signupFlow(respData, email_notify, selectedPlan?) {
    try {
      const region = this.storageService.getLocalStore('region');
      const uId = respData.user_id;
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Membership Actions',
        eventAction: 'Register',
        eventLabel: 'Success',
        loginStatus: 'True',
        userId: respData .user_id,
        notificationStatus: email_notify,
        region: (region ? region : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
      if (respData.verifyEmail === 1) {
        this.storageService.setLocalStore('mail', respData.email);
        this.uikitService.notifySuccess(respData);
        this.router.navigateByUrl('/auth/verify');
      } else {
        const uId = respData.user_id;
        if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', uId);
        const dId: string = this.storageService.getLocalStore('d_id');
        const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
        $('body').append(bodyScript);
        this.storageService.setLocalStore('a_t', respData.access_token);
        this.storageService.setLocalStore('r_t', respData.refresh_token);
        this.storageService.setLocalStore('u_id', uId);
        this.storageService.setLocalStore('logged_in', respData.logged_in);
        this.storageService.setLocalStore('operator_reference_number',respData.operator_reference_number);
        this.storageService.setLocalStore('customer_identifier_type',respData.customer_identifier_type);
        this.paymentService.paymentMethodLength = respData.stripe_payment_method.length;
        let cookieuName = 'u_id';
        let cookieuValue = uId;
        document.cookie = cookieuName + "=" + cookieuValue + ";domain="+environment.cookieDomain+";path=/";
        const updateMemoryData = {
          login_by: respData.user_login_by,
          user_picture: respData.user_picture,
          user_name: respData.user_name,
          user_type: respData.user_type,
          user_telco: respData.user_telco,
          one_time_subscription: respData.one_time_subscription,
          u_id: respData.user_id
        };
        this.memorySource.next(updateMemoryData);
        this.storageService.setLocalStore('sessionStorage', updateMemoryData);
        this.storageService.setLocalStore('event_user_status', respData.user_type);
        this.baseService.guestSource.next(false);
        const queryParamTv = this.route.snapshot.queryParams['app'];
        const discountType = this.storageService.getLocalStore('discount_type');
        const discountCode = this.storageService.getLocalStore('discount_code');
        if (discountCode && discountType === VOUCHER && this.isSVOD && this.planService.plansLength) {
          const Coupendata = { voucher_code: discountCode, d_type: 'web' };
          const couponResp: any = await this.subscriptionService.applyCoupon(Coupendata);
          if (couponResp.status_code === 200) {
            const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
            if(operatorVoucher) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { step: '5', auth: null },
                queryParamsHandling: 'merge'
              })
            } else this.router.navigateByUrl('/voucher/success');
          } else {
            if (couponResp.error && couponResp.error.code !== 1002) {
              this.uikitService.notifyError(couponResp);
            }
            this.router.navigateByUrl('/home')
          }
          this.storageService.removeLocalStore('discount_code');
          this.storageService.removeLocalStore('discount_type');
          this.storageService.removeLocalStore('operator_voucher');
        } else if(discountCode && this.isSVOD && this.planService.plansLength && (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)){
          const Coupendata = { code: discountCode, d_type: 'web',operator_reference_number:this.storageService.getLocalStore('operatorReference'),customer_identifier_type:this.storageService.getLocalStore('customerIdentifierType')};
            const couponResp2: any = await this.voucherService.bulsatSubcription(Coupendata);
            if (couponResp2.status_code === 200) {
              const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
              if(operatorVoucher) {
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { step: '5', auth: null },
                  queryParamsHandling: 'merge'
                })
              } else this.router.navigateByUrl('/voucher/success');
            } else {
              if (couponResp2.error && couponResp2.error.code !== 1002) {
                this.uikitService.notifyError(couponResp2);
              }
            }
        } else if (discountCode && discountType === COUPON && this.isSVOD && this.planService.plansLength) {
          if (respData.display_payment === 1 && this.planService.plansLength > 0) this.proceedPayment(selectedPlan);
          else this.router.navigateByUrl('/home');
        } else if (respData.display_payment === 1 && this.isSVOD && this.planService.plansLength) {
          // After signup we are proceeding to the payment for the plan which is selected before signup
          this.proceedPayment(selectedPlan);
        } else {
          this.router.navigateByUrl('/home');
        }
        await this.getUserSettings();
        this.baseService.signinCaptchaValidation.next(0);
        this.storageService.removeLocalStore('authErrorCounter');
      }
    } catch (error) {
      console.log(error)
    }
  }

  refreshToken() {
    const rT = this.storageService.getLocalStore('r_t');
    if(!rT) {
      this.errorLogout();
      return
    }
    const refreshAPI = environment.apiUrl + environment.version + refreshToken;
    const dId: string = this.storageService.getLocalStore('d_id');
    const gId: string = this.storageService.getLocalStore('g_id');
    const uId: string = this.storageService.getLocalStore('u_id');
    let params: HttpParams = new HttpParams()
      .set('d_type', 'web')
      .set('d_id', dId);
    if (uId) {
      params = params.append('u_id', uId);
    }
    else {
      params = params.append('g_id', gId);
    }
    return this.http.get(refreshAPI, { params }).pipe(
      tap((response:any) => {
        if (response.status_code === 200) {
          const respData = response.data;
          this.storageService.setLocalStore('a_t', respData.access_token);
          this.storageService.setLocalStore('r_t', respData.refresh_token);
        }
      })
      // ,catchError(async (error) => this.errorLogout())
    );
  }

  logout(reset = false) {
    this.reset = reset;
    const dId: string = this.storageService.getLocalStore('d_id');
    const logoutAPI: string = environment.apiUrl + environment.version + logout + dId;
    const params: HttpParams = new HttpParams()
      .set('d_type', 'web');
    this.http.get(logoutAPI, { params }).subscribe((response: any) => {
      this.baseService.loaderSource.next(false);
      if (response.status_code === 200) {
        const uId: string = this.storageService.getLocalStore('u_id');
        const region: string = this.storageService.getLocalStore('region');
        if(this.settingsService.dEngageFlag && window.dengage){
          window.dengage('setContactKey', null);
        }
        const datalayerData = {
          event: 'GAEvent',
          eventCategory: 'Membership Actions',
          eventAction: 'Logout',
          eventLabel: 'Success',
          loginStatus: 'False',
          userId: (uId ? uId : ''),
          notificationStatus:   localStorage.getItem('emailNotify') ||  'False',
          region: (region ? region : ''),
          platform: this.baseService.browserType,
          userType: this.storageService.getLocalStore('user_type'),
          operator: this.storageService.getLocalStore('operator_name')
        };
        this.datalayerService.addDatalayer(datalayerData);
        this.removeLogoutData();
        this.uikitService.notifySuccess(response);
        if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', '');
        // if (this.baseService.loginMode === 1) {
        //   //this.router.navigateByUrl('/auth/signin');
        // }
        // else {
        //   //this.router.navigateByUrl('/home');
        // }
        if(!reset){
          let utm_url = this.settingsService.getUTMurl(environment.paywallURL);
          setTimeout(()=>{
            window.location.href = utm_url;
          }, 1500);
        }
        this.baseService.showCardData.next({ no_past_due: true, session_id: undefined });
        // setTimeout(()=>{
        //   window.location.href = utm_url;
        // }, 2000);
      } else {
        this.uikitService.notifyError(response);
      }
    }, (error: HttpErrorResponse) => {
      if (error && error.error.error.code !== 1002) {
        this.uikitService.notifyError(error);
      }
      this.baseService.loaderSource.next(false);
    });
  }
  errorLogout() {
    if(this.reset == false){
      this.removeLogoutData()
      const routeUrl = this.settingsService.routeUrl || environment.routeUrl;
      setTimeout(()=>{
        window.location.assign(routeUrl);
      }, 3000);
    }
  }

  async removeLogoutData() {
    if(this.settingsService.dEngageFlag && window.dengage){
      window.dengage('setContactKey', '');
    }
    this.memorySource.next(undefined);
    this.storageService.removeLocalStore('a_t');
    this.storageService.removeLocalStore('r_t');
    this.storageService.setLocalStore('logged_in', '0');
    this.storageService.removeLocalStore('u_id');
    document.cookie = 'u_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'd_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'g_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'hoopla_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'universal_user=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'; 
    document.cookie = 'hoopla_transaction_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'hoopla_start_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'hoopla_end_time=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.storageService.removeLocalStore('sessionStorage');
    this.storageService.removeLocalStore('contentURL');
    this.storageService.removeLocalStore('event_user_status');
    this.storageService.removeLocalStore('operator_name');
    this.storageService.removeLocalStore('user_type');
    this.storageService.removeLocalStore('epg_live_channel');
    this.storageService.removeLocalStore('sso_or_ftp');
    this.storageService.removeLocalStore('operator_voucher');
    this.storageService.removeLocalStore('discount_type');
    this.storageService.removeLocalStore('discount_code');
    this.storageService.removeLocalStore('cu_id');
    this.storageService.removeLocalStore('hoopla_user');
    this.storageService.removeLocalStore('hoopla_transaction_id');
    this.storageService.removeLocalStore('hoopla_start_time');
    this.storageService.removeLocalStore('hoopla_end_time');
    this.storageService.removeLocalStore('universal_user');
    this.storageService.removeLocalStore('subsId')
    this.baseService.guestSource.next(true);
    await this.loadService.getToken();
  }

// this service calling for User reset and change the password details.
  resetPassword(data){
    const resetPasswordAPI = environment.apiUrl + 'v2/auth/password/reset/update';
    this.http.post<any>(resetPasswordAPI, data).subscribe((response)=>{
      this.uikitService.staticSuccessMessage("Password successfully changed! Please Login Again.");
      this.router.navigateByUrl('/auth/signin');
    },(error: HttpErrorResponse) => {
      console.log(error.error.error.code)
      if(error.error.error.code === 1116){
        this.uikitService.staticErrorMessage("The link has already expired. Please try once again resetting password from the portal/app.");
        this.router.navigateByUrl('/auth/forgot')
      }else this.uikitService.staticErrorMessage("Password reset failed");
    })
  }

  hooplaLogin(data) {
    const loginAPI: string = environment.apiUrl + environment.version + hooplaProcess;
    return new Promise((resolve) => {
      return this.http.post<any>(loginAPI, data).subscribe(
        (response) => {
          if (response.status_code === 200) {
            console.log(data);
            this.storageService.setLocalStore('hoopla_user', '1');
            this.storageService.setLocalStore('universal_user', data.loginCustomer);
            this.storageService.setLocalStore('hoopla_transaction_id', response.data.transaction_id);
            if (data.loginCustomer == 'hoopla') {
              this.storageService.setLocalStore('hoopla_start_time', response.data.start_time);
              this.storageService.setLocalStore('hoopla_end_time', response.data.end_time);
            }
            if (data.loginCustomer == 'snappet') {
              this.storageService.setLocalStore('contentURL', decodeURIComponent(data.contentUrl));
            }
            console.log(response);
            this.hooplaUser.next(true);
            this.setAuthData(response);
          } else {
            this.uikitService.staticErrorMessage(response.error.message);
            this.logout();
          }
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          const errorMsg = error?.error?.error?.message;
          this.uikitService.staticErrorMessage(errorMsg);
          this.logout();
          resolve(error);
        }
      );
    });
  }

  setAuthData(response) {
    const respData = response.data;
    const uId = respData.user_id;
    let userType = 'Free User';
    let operatorName = '';
    this.storageService.setLocalStore('a_t', respData.access_token);
    this.storageService.setLocalStore('r_t', respData.refresh_token);
    this.storageService.setLocalStore('u_id', uId);
    this.storageService.setLocalStore('logged_in', respData.logged_in);
    const updateMemoryData = {
      login_by: respData.user_login_by,
      user_picture: respData.user_picture,
      user_name: respData.user_name,
      user_type: respData.user_type,
      user_telco: respData.user_telco,
      is_voucher_applied: respData?.is_voucher_applied,
      partner_name: respData?.voucher?.partner_name,
      partner_description: respData?.voucher?.partner_description,
      one_time_subscription: respData.one_time_subscription,
      u_id: uId
    };
    if (respData.user_telco) {
      userType = 'Operator User';
      operatorName = respData.user_telco;
    } else if (respData.is_voucher_applied && respData.is_voucher_applied === 1) {
      userType = 'Voucher User';
      operatorName = respData.voucher.partner_name + '-' + respData.voucher.partner_description;
    } else if (respData.user_type === 1) {
      userType = 'Paid User';
    }
    this.baseService.showCardData.next({ no_past_due: true, session_id: undefined });
    this.storageService.setLocalStore('user_type', userType);
    this.storageService.setLocalStore('operator_name', operatorName);
    this.memorySource.next(updateMemoryData);
    this.storageService.setLocalStore('sessionStorage', updateMemoryData);
    this.storageService.setLocalStore('event_user_status', respData.user_type);
    this.baseService.guestSource.next(false);
    //const eventAction = gaLogin;
    const eventLabel = 'Success - ' + respData.user_login_by + '/Username';
    //this.memberGAEvent(eventAction, eventLabel);
    const loginSucessMsg = this.configService.localeKeys?.login_msg_login_succ_def;
    this.uikitService.staticSuccessMessage(loginSucessMsg);
    const authenticate: string = this.storageService.getLocalStore('authenticate');
    const contentURL: string = this.storageService.getLocalStore('contentURL');
    if (authenticate) {
      this.storageService.removeLocalStore('authenticate');
      this.router.navigateByUrl('/account/authenticate');
    } else if (contentURL) {
      this.storageService.removeLocalStore('contentURL');
      location.href = contentURL;
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  clearVariable(){
    this.oldAccessToken = undefined;
    this.newAccessToken = undefined;
    this.newUserId = undefined;
    this.loginPostData = undefined;
    this.loginResponse = undefined
  }
}
