<footer id="footer-element">
    <div id="footer-container">
        <div class="w-100 d-flex list-align">
            <div class="footer-list">
                <div class="browse" *ngIf="!isMobile">
                    <div class="head mb-12"><strong>{{configService.localeKeys['kw_browse'] || 'Browse'}}</strong></div>
                    <div *ngFor="let value of categoryService.categories | async">
                        <div class="list cursor-pointer" (click)="redirect(value.slug)">{{value?.title}} </div>
                    </div>
                </div>
                <div class="help">
                    <div class="head mb-12">
                        <strong *ngIf="configService.localeKeys['kw_help'].length >= 12 && innerWidth <= 400">{{configService.localeKeys['kw_help'].substring(0, 15) || 'Help'}}...</strong>
                        <strong *ngIf="configService.localeKeys['kw_help'].length <= 12 && innerWidth <= 400">{{configService.localeKeys['kw_help'] || 'Help'}}</strong>
                        <strong *ngIf="innerWidth > 400">{{configService.localeKeys['kw_help'] || 'Help'}}</strong>
                    </div>
                    <div class="list">
                        <app-link [footerKey]="'faq'" [footerNavText]="configService.localeKeys['kw_faq'] || 'FAQ'"></app-link>
                    </div>
                    <div class="list cursor-pointer" *ngIf="settingsService.feedbackEnabled" (click)="feedbackRedirect()">{{configService.localeKeys['foot_btn_feed_def'] || 'FeedBack'}} </div>
                    <div class="list">
                        <app-link [footerKey]="'contact'" [footerNavText]="configService.localeKeys['foot_btn_contact_us_def'] || 'Contact Us'"></app-link>
                    </div>
                    <div class="list cursor-pointer" *ngIf="settingsService.whereToWatch" [routerLink]="['/app/where-to-watch']">{{configService.localeKeys['where_to_watch_btn']}} </div>
                </div>
                <div class="info">
                    <div class="head mb-12">
                        <strong *ngIf="configService.localeKeys['kw_information'].length >= 12 && innerWidth <= 400">{{configService.localeKeys['kw_information'].substring(0, 15) || 'Information'}} ...</strong>
                        <strong *ngIf="configService.localeKeys['kw_information'].length <= 12 && innerWidth <= 400">{{configService.localeKeys['kw_information'] || 'Information'}}</strong>
                        <strong *ngIf="innerWidth > 400">{{configService.localeKeys['kw_information'] || 'Information'}}</strong></div>
                    <div class="list">
                        <app-link [footerKey]="'terms'" [footerNavText]="configService.localeKeys?.foot_btn_trms_use_def"></app-link>
                    </div>
                    <div class="list">
                        <app-link [footerKey]="'privacy'" [footerNavText]="configService.localeKeys['foot_btn_pri_poli_def'] || 'Privacy Policy'"></app-link>
                    </div>
                    <div class="list">
                        <app-link [footerKey]="'about'" [footerNavText]="configService.localeKeys['foot_btn_about_us_def']|| 'About Us'"></app-link>
                    </div>
                    <div class="list">
                        <!-- OneTrust Cookies Settings button start -->
                        <!-- <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button> -->
                        <!-- OneTrust Cookies Settings button end -->
                    </div>
                </div>
            </div>
            <div *ngIf="showLanguageTop" id="lang-dropdown">
                <li *ngIf="localeLength>1" id="select-text" class="dropdown nav-profile">
                    <a id="lang-text-align" class="dropdown-toggle" data-toggle="dropdown">
                        <span id="select-lang-text">{{configService.activeLocale || selectedLocale}}</span>
                        <img class="langdown-img" src="assets/{{appName}}/img/down-arrow.svg">
                    </a>
                    <ul id="dropdown-list" class="dropdown-menu" [ngClass]="dropPosBottom ? 'dropdown-items': 'dropdown-items-sec'"  role="menu">
                        <div class="border-bot more-profile lang-align">
                            <li *ngFor="let value of locales;let index =index;">
                                <a role="menuitem" (click)="setLocale(index,value)" tabindex="-1">
                                    {{value.translatedLanguage}}</a>
                            </li>
                        </div>
                    </ul>
                </li>
            </div>
        </div>
        <div class="w-100" id="download-text" *ngIf="(settingsService.appstore || settingsService.playstore) && !restrictedUser"><strong>{{configService.localeKeys['kws_download_def']}}</strong></div>
        <!-- <div class="w-100" id="download-text" *ngIf="!restrictedUser"><strong>{{configService.localeKeys['kws_download_def']}}</strong></div> commented this as it need for reading corner -->
        <div class="footer-icon">
            <div class="store-icon" *ngIf="(settingsService.appstore || settingsService.playstore) && !restrictedUser">
                <a *ngIf="settingsService.appstore" target="_blank" [attr.href]="settingsService.appstore"><img src="assets/{{appName}}/img/apple-store.svg" alt=""></a>
                <a *ngIf="settingsService.playstore" target="_blank" [attr.href]="settingsService.playstore"><img src="assets/{{appName}}/img/play-store.svg" alt=""></a>
            </div>
            <div *ngIf="!showLanguageTop" id="lang-dropdown">
                <li *ngIf="localeLength>1" id="select-text" class="dropdown nav-profile">
                    <a id="lang-text-align" class="dropdown-toggle" data-toggle="dropdown">
                        <span id="select-lang-text">{{configService.activeLocale || selectedLocale}}</span>
                        <img class="langdown-img" src="assets/{{appName}}/img/down-arrow.svg">
                    </a>
                    <ul id="dropdown-list" class="dropdown-menu"  [ngClass]="dropPosBottom ? 'dropdown-items': 'dropdown-items-sec'" role="menu">
                        <div class="border-bot more-profile lang-align">
                            <li *ngFor="let value of locales;let index =index;">
                                <a role="menuitem" (click)="setLocale(index,value)" tabindex="-1">
                                    {{value.translatedLanguage}}</a>
                            </li>
                        </div>
                    </ul>
                </li>
            </div>
            <div *ngIf="settingsService.socialAvail" class="social-icon">
                <a *ngIf="settingsService.socialLink.facebook" target="_blank" [attr.href]="settingsService.socialLink.facebook" class="mr-3"><img src="assets/{{appName}}/img/footer_facebook.svg" alt=""></a>
                <a *ngIf="settingsService.socialLink.instagram" target="_blank" [attr.href]="settingsService.socialLink.instagram" class="mr-3"><img src="assets/{{appName}}/img/footer_instagram.svg" alt=""></a>
                <!-- <a *ngIf="settingsService.socialLink.twitter" target="_blank" [attr.href]="settingsService.socialLink.twitter" class="mr-3"><img src="assets/{{appName}}/img/footer_twitter.svg" alt=""></a> -->
            </div>
        </div>
    </div>
</footer>
