<nav [ngClass]="{'navbar-top': showUpdateCard}" *ngIf="(appName === 'filmbox' || appName === 'film1') ? (!showAndroidApp && !showIosApp) : true" class="navbar navbar-default navbar-fixed-top nav-scroll" role="navigation" >
        <div class="header_section" id="header-section">
        <div id="header-pad" class="d-align-center">
            <div class="d-align-center" id="check">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-menu" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                </button>
                <a class="home-logo"><img (click)="redirectURL()"  loading="lazy" [src]="logo"></a>
            </div>
                <div class="collapse navbar-collapse" id="main-menu">
                    <ul (mouseleave)="hoverOutClass()"  class="nav navbar-nav left-align" id="listCategory">
                        <li class="dropdown category-list" *ngFor="let value of (categoryService.categories | async); let a=index"  id="{{ 'object-' + a }}"  (mouseover)="hoverIn(value, a)">
                            <a (click)="redirect(value.slug, value)" [ngClass]="{'active-category': value.slug == selectedMenuItem}" class="dropdown-toggle category" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{{value.title}} <span *ngIf="value.sub_menus.length > 0" class="caret"></span></a>
                            <img class="category-arrow-asset" *ngIf="value.slug === catName && categorySelected"  src="assets/{{appName}}/img/drop-up.svg">
                            <div class="dropdown-menu">
                                <div class="container-fluid" [hidden]="!categorySelected"  [ngClass]="{'category-hover': (categorySelected==true)}">
                                    <div id="dropdown-menu-header" class="subcategory-list">
                                        <div class="content">
                                            <div class="row all-items">
                                                <ng-container *ngFor="let submenu of categoryInfo; let q = index">
                                                    <div class="sub-menu-card" *ngIf="(submenu?.lists?.length)">
                                                        <h6 *ngIf="submenu.display_title =='1'" (click)="redirectToViewAll(submenu)"class="sub-menu-header">{{ configService.localeKeys[submenu.submenu_translation_key] || submenu.submenu_title}} </h6>
                                                        <div *ngIf="(submenu.submenu_content_row*submenu.submenu_content_col) < submenu.count ? submenu.submenu_content_col : ceilData(submenu.count/submenu.submenu_content_row); let col">
                                                            <ul [ngStyle]="{'grid-template-rows': 'repeat(' + submenu.submenu_content_row + ', auto)' }" class="subcategory-list-items" *ngFor="let loop3 of counter(col);let i= index">
                                                                <ng-container *ngIf="submenu.submenu_content_row > submenu.count ? submenu.count : submenu.submenu_content_row; let row">
                                                                    <ng-container *ngFor="let contents of counter(row);let j = index">
                                                                        <li class="list-items-style" *ngIf="(((i * submenu.submenu_content_row) + j) < ((submenu.count > submenu.submenu_content_row*submenu.submenu_content_col) ? ((submenu.submenu_content_row*submenu.submenu_content_col)-1) : (submenu.submenu_content_row*submenu.submenu_content_col)) && (submenu.lists[(i*submenu.submenu_content_row)+j]?.title  || submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title))">
                                                                            <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_collection == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j], submenu)">{{submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title}}</a>
                                                                            <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_series == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                                            <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_livechannel == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <li *ngIf="submenu?.count > (submenu.submenu_content_row*submenu.submenu_content_col) && ((i+1)==submenu.submenu_content_col)"><a class="view-all-style" (click)="redirectToViewAll(submenu)"> {{ configService.localeKeys.homepg_menu_viewall || 'View all'}} </a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li (mouseover)="myListHover()" *ngIf="!(baseService.guest | async) && !restrictedUser" class="category-list">
                            <a [routerLink]="['/wishlist']" [queryParams]="{menu_item: 'wishlist'}" (click)="onWishlistClick('wishlist')" class="nav-no-border-bottom" [ngClass]="{'active-category': selectedMenuItem == 'wishlist'}">{{ configService.localeKeys?.menu_btn_wishlist_def}}</a>
                        </li>
                    </ul>
                </div><!-- /.navbar-collapse -->
            </div>
        <ul class="nav navbar-nav navbar-right right-align d-align-center">
            <li>
                <form id="form" method="post" name="searchForm">
                    <input #inputSearchElement id="inputSearch" (click)="inputClick($event)" class="search-field" (blur)="focusOutInput()" (focus)="focusInput()" placeholder="{{ configService.localeKeys?.what_to_watch }}" type="search" name="searchName" [(ngModel)]="searchName"
                        spellcheck="false" autocomplete="off">
                    <button id="searchIcon" class="search-box-xs searchButton" type="submit" (click)="searchShow($event)"><img loading="lazy" class="search-icon1" src="assets/{{appName}}/img/search.svg">
                    </button>
                    <button id="cancelIcon" (click)="closeSearch()" class=" search-box-xs searchButton"><img loading="lazy" class="search-icon1" src="assets/{{appName}}/img/close.svg">
                    </button>
                </form>
            </li>
            <li *ngIf="!guest && !restrictedUser" class="dropdown nav-profile">
                <a *ngIf="!guest" class="dropdown-toggle cursor-pointer" data-toggle="dropdown">
                    <div class="nav-icon-img" (click)="closeDrop()">
                        <img loading="lazy" src="assets/{{appName}}/img/profile.svg">
                        <!-- <img loading="lazy" class="nav-profile-img bg-img" src="assets/{{appName}}/img/profile.svg"> -->
                    </div>
                    <!-- {{userName}} -->
                    <!-- <strong class="caret"></strong> -->
                </a>
                <ul class="dropdown-menu nav-profile-menu align-profile" (click)="closeSearch()">
                    <div class="border-bot more-profile">
                        <li>
                            <a [routerLink]="['/account/settings']">
                                {{ configService.localeKeys?.menu_btn_my_acc_def}}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/wishlist']">
                                {{ configService.localeKeys?.menu_btn_my_list_def}}
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/app/feedback']">
                                {{ configService.localeKeys?.menu_btn_feed_def}}
                            </a>
                        </li>
                        <!-- <li>
                            <a (click)="redirect('/account/authenticate')" ui-sref="profile.authenticate({region: region})">
                                {{ configService.localeKeys?.menu_btn_auth_def}}
                            </a>
                        </li> -->
                        <li *ngIf="settingsService.activateYourTvFromTVCode || settingsService.activateYourTvFromWebCode">
                            <a [routerLink]="['/account/activate-tv']">
                                {{ configService.localeKeys?.activate_your_tv}}
                            </a>
                        </li>
                        <li>
                            <a class="cursor-pointer" (click)="logout()">
                                {{ configService.localeKeys?.menu_btn_log_out_def}}
                            </a>
                        </li>
                    </div>
                </ul>
            </li>
            <li *ngIf="guest && !restrictedUser">
                <a *ngIf="operatorLogin!=0" [routerLink]="['/login/option']" class="login-btn">{{ configService.localeKeys?.login_title_log_in_def}}</a>
                <a *ngIf="operatorLogin==0" [routerLink]="['/auth/signin']" class="login-btn">{{ configService.localeKeys?.login_title_log_in_def}}</a>
            </li>
        </ul>

    </div>
        </div>
</nav>
