import { AfterViewInit, Component, ElementRef,Renderer2, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SettingsService, BaseService, StorageService } from '../services';
import { ConfigurationService } from '../services/configuration.service';
import { LoggerService } from '../authentication/logger.service';
import { CategoriesService } from '../services/categories.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SearchComponent } from 'src/app/shared/components';
import { SeoService } from '../services/seo.service';
import { SubscriptionService } from '../services/subscription.service';
import { L_CATEGORY_META_DATA } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { HomeService } from 'src/app/core/services/home.service';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-header',
  templateUrl: `./${environment.htmlAppName}/header.component.html`,
  styleUrls: [`./${environment.appName}/header.component.scss`, `./header.component.scss`],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  guest;
  operatorLogin;
  userPicture;
  userName;
  showIosApp = true;
  showAndroidApp = true;
  categories;
  channelsList;
  selectedLocale;
  selectedLocaleKey;
  categorySelected = false;
  locales;
  localeLength;
  siteLogo;
  imagePath;
  catName;
  categoryInfo;
  isLive;
  enableLive;
  categoryId: any;
  memoryStorage;
  logo: any;
  category: any;
  updatePayment = false;
  sessionId: any;
  showUpdateCard: boolean;
  logoUrlLink: boolean;
  logohref:any;
  inputBox;
  searchForm: FormGroup;
  searchName = '';
  showCancel;
  dialogRef;
  showSearch;
  windowWidth;
  counter = Array;
  @ViewChild('inputSearchElement', {static: false}) inputSearchElement: ElementRef;
  selectedMenuItem: any;
  appName;
  restrictedUser;
  mobileBrowser;
  constructor(public settingsService: SettingsService, public baseService: BaseService,
              public configService: ConfigurationService, private loggerService: LoggerService,
              public categoryService: CategoriesService, private route: ActivatedRoute, public dialog: MatDialog,
              private storageService: StorageService, private router: Router, public contentService:ContentService,
              public el: ElementRef, public renderer: Renderer2,) {
    this.operatorLogin = this.configService.operatorLogin;
    this.selectedLocale = this.configService.locales[this.configService.localeIndex].lang_name || 'Select Locale';
    this.selectedLocaleKey = this.configService.locales[this.configService.localeIndex].lang_code;
    this.locales = this.configService.locales;
    this.localeLength = this.locales.length;
    this.imagePath = this.settingsService.imagePath;
    this.siteLogo = this.settingsService.siteLogo;
    this.isLive = this.baseService.isLive;
    this.enableLive = this.settingsService.enableLive;
    this.logo = this.settingsService.logo;
    this.appName = environment.appName;
    this.mobileBrowser = (this.baseService.showAndroidApp || this.baseService.showIosApp)
    this.restrictedUser = (environment.isEzProxy || (this.storageService.getLocalStore('hoopla_user') == '1')) ? true : false;
    $('body').click(() => {
      if(this.windowWidth > 320 && this.windowWidth < 1001 && !this.mobileBrowser){
        $('#inputSearch').removeClass('closeSearch');
        document.getElementById('listCategory').style.display = 'block';
        $('#inputSearch').blur();
      }
      if(this.windowWidth > 320 && this.windowWidth < 1001 && this.mobileBrowser){
        $('#inputSearch').removeClass('closeSearch');
        document.getElementById('listCategory').style.display = 'block';
        this.inputBox.setAttribute('style', 'width: 23px ');
      }
    })
  }
  @HostListener('dblclick', ['$event'])
  clickEvent(event) {
    if (event.target?.id !== 'inputSearch') {
      event.srcElement.setAttribute('disabled', true);
    }
  }
  // this method calling click search box
  inputClick(e){
    e.stopPropagation();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(query => {
       this.selectedMenuItem = query.category_title ? query.category_title : query.menu_item;
    });
  this.baseService.guest.subscribe(data => {
      this.guest = data
    });
    this.baseService.showCardData.subscribe(data => {
      this.showUpdateCard = !data?.no_past_due;
    })
    this.logohref = this.settingsService.paywallEnabled ? environment.paywallURL: environment.routeUrl;
    this.loggerService.memoryStorage.subscribe(data => {
      if (data) {
        this.memoryStorage = data;
        this.userName = (this.memoryStorage.user_name !== '' &&
          this.memoryStorage.user_name !== undefined) ? this.memoryStorage.user_name : '';
        if (this.memoryStorage.user_picture.indexOf('http://') === 0 || this.memoryStorage.user_picture.indexOf('https://') === 0) {
          this.userPicture = this.memoryStorage.user_picture;
        } else {
          this.userPicture = this.settingsService.imagePath + this.memoryStorage.user_picture;
        }
      }
    });
    this.loggerService.hooplaUser.subscribe(data => {
      if (data === true) this.restrictedUser = true;
    });
    this.showAndroidApp = this.baseService.showAndroidApp;
    this.showIosApp = this.baseService.showIosApp;
    if (((this.appName ==='filmbox' || this.appName === 'film1') ? (!this.showAndroidApp && !this.showIosApp) : true) && this.storageService.getLocalStore('a_t')
    && this.storageService.getLocalStore('r_t')) {
      this.categoryService.getNextVersionCategories().subscribe();
    }
    if (((this.appName ==='filmbox' || this.appName === 'film1') ? (!this.showAndroidApp && !this.showIosApp) : true) && this.settingsService.displayLandingLocale) {
      if (this.configService.locales.length > 1) {
        const locale = this.storageService.getLocalStore('locale');
        if (!locale) {
          this.configService.localeActive = true;
          // $('.overlay').css('overflow-y', 'auto');
          // $('.overlay').css('display', 'block');
          // $('body').css('overflow-y', 'hidden');
          // $('body').css('height', '100%');
        } else {
          this.configService.localeActive = false;
        }
      }
    } else {
      this.configService.localeActive = false;
    }
  }

  ngAfterViewInit(){
    fromEvent(this.inputSearchElement.nativeElement,'keyup')
      .pipe(
          filter(Boolean),
          debounceTime(400),
          distinctUntilChanged(),
          tap((event:KeyboardEvent) => {
            this.goToSearchPage(event);
          })
      )
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    if(this.mobileBrowser){
      if(this.windowWidth > 320 && this.windowWidth < 1001){
        $('#inputSearch').removeClass('closeSearch');
        document.getElementById('listCategory').style.display = 'block';
        this.inputBox.setAttribute('style', 'width: 23px ');
      }
    }else{
      if(this.windowWidth > 320 && this.windowWidth < 1001){
        document.getElementById('listCategory').style.display = 'block';
        $('#inputSearch').blur();
      } else {
        document.getElementById('listCategory').style.display = 'block';
        $('#inputSearch').blur();
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeSearch();
  }

  logout() {
    this.loggerService.logout();
  }

  hoverIn(value, e) {
    let listAllZero = value.sub_menus.every(obj => obj.count == 0);
    if (!value.sub_menus || !value.sub_menus?.length || listAllZero) {
      this.categorySelected = false;
      this.catName = '';
      return;
    }
    document.getElementById('dropdown-menu-header').style["left"] = (document.getElementById('object-' + e).offsetLeft + 5) + "px";
    if (value === 'live') {
      this.categoryInfo = this.categoryService.channelsList;
      this.catName = 'live';
    } else if (value === 'sub' && this.categoryInfo) {
        this.catName = this.categoryInfo?.slug;
        this.category = this.categoryInfo?.slug;
    } else {
      this.catName = value?.slug;
      this.categoryInfo = value?.sub_menus;
      this.categorySelected = true;
    }
  }

  myListHover() {
    this.categorySelected = false;
    this.catName = '';
  }

  hoverOutClass = function () {
    this.catName = '';
    this.categorySelected = false;
  };

  setLocale(i, value) {
    this.selectedLocale = value.lang_name;
    this.selectedLocaleKey = value.lang_code;
    this.configService.localeIndex = i;
    this.configService.setlocaleCookie(this.selectedLocaleKey);
    this.storageService.setLocalStore('locale', this.selectedLocaleKey);
    this.storageService.setLocalStore('localeName', this.selectedLocale);
    this.configService.locale = this.selectedLocaleKey;
    window.location.reload();
  }

  setTitleValue(catName) {

  }

  scrollToRight() {

  }
  scrollToLeft() {

  }

  updateConstHeader(category:string){
    this.baseService.headerCategoryId.next(category);
  }

  searchShow(e) {
    if(window.innerWidth < 769)this.closeDropdown();
    e.stopPropagation();
    this.inputBox = <HTMLInputElement> document.getElementById("inputSearch");
    if(this.mobileBrowser){
      if(this.windowWidth > 320 && this.windowWidth < 1001){
        document.getElementById('listCategory').style.display = 'none';
        }
        this.inputBox.setAttribute('style', 'width: 320px !important;');
        if(this.windowWidth > 320 && this.windowWidth < 485){
          this.inputBox.setAttribute('style', 'width: 200px !important');
        }
        if(this.windowWidth >= 485 && this.windowWidth < 665){
          this.inputBox.setAttribute('style', 'width: 220px !important');
        }
        $('#inputSearch').addClass('closeSearch')
    }
    $('#inputSearch').focus();
    $('#inputSearch').addClass('closeSearch')
    if(this.inputBox.value == ''){
      return;
    }
    this.baseService.searchData.next(this.inputBox.value);
    if(this.inputBox.value != ''){
      if(!this.dialog.openDialogs.length){
        this.settingsService.showHeader = true;
        this.dialogRef = this.dialog.open(SearchComponent, {
          panelClass: 'my-full-screen-dialog',
          data: {},
        });
      }
    }
  }

  redirect(value, val?) {
    this.baseService.redirect(value, val);
    if(window.innerWidth < 769)this.closeDropdown();
    this.closeSearch();
  }
closeDrop(){
  if(window.innerWidth < 769)this.closeDropdown();
}
  closeDropdown() {
    const buttonElement = this.el.nativeElement.querySelector('.navbar-toggle');
    if (buttonElement) {
      this.renderer.addClass(buttonElement, 'collapsed');
      this.renderer.setAttribute(buttonElement, 'aria-expanded', 'false');
      const targetId = buttonElement.getAttribute('data-target');
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        this.renderer.removeClass(targetElement, 'in');
        this.renderer.setAttribute(targetElement, 'aria-expanded', 'false');
      }
    }
  }

  redirectCollection(data, value, val?) {
    this.baseService.redirectCollection(data, value, val);
    this.closeSearch();
  }
// this method is calling to redirect home page
  redirectURL() {
    const user_id = this.storageService.getLocalStore('u_id');
    if(user_id || !this.settingsService.paywallEnabled) {
      this.router.navigateByUrl('/home');
    } else {
      let utm_url = this.settingsService.getUTMurl();
      window.location.href = utm_url;
    }
    this.baseService.setPreviousSlugValue('home');
    if(window.innerWidth < 769)this.closeDropdown();
    this.closeSearch();
  }
// this method for search icon showing depend on the text.
  goToSearchPage(e){
    this.showSearch = <HTMLInputElement> document.getElementById("searchIcon");
    this.showCancel = <HTMLInputElement> document.getElementById("cancelIcon");
    if(this.showCancel){
      this.showCancel.style.opacity = '1';
      this.showCancel.style.zIndex = '1';
    }
    if(this.showSearch) {
      this.showSearch.style.zIndex = '0';
      this.showSearch.style.opacity = '0';
    }
    if(this.searchName == ''){
      this.closeSearch();
    }
    if(this.searchName.length > 3){
      this.searchShow(e);
    }
  }
// this method for close the cross icon base on the search text
  closeSearch(){
    if(window.innerWidth < 769)this.closeDropdown();
    this.searchName = '';
    if(this.showCancel) {
      this.showCancel.style.opacity = '0';
      this.showSearch.style.opacity = '1';
      this.showCancel.style.zIndex = '0';
      this.showSearch.style.zIndex = '1';
    }
    if(this.mobileBrowser){
      if(this.windowWidth > 320 && this.windowWidth < 1001){
        $('#inputSearch').removeClass('closeSearch');
        this.inputBox.setAttribute('style', 'width: 23px ');
        document.getElementById('listCategory').style.display = 'block';
      }
    }else{
      if(this.windowWidth > 320 && this.windowWidth < 1001){
        this.inputBox.setAttribute('style', 'width: 23px ');
        document.getElementById('listCategory').style.display = 'block';
        $('#inputSearch').blur();
      } else {
        this.inputBox.setAttribute('style', 'width: 280px ')
      }
    }
    if(this.dialog?.openDialogs?.length) this.dialogRef.close();
    this.baseService.headerCloseEvent.next(true);
  }
// this method for search box is exsponded
  focusInput(){
    if(this.windowWidth > 320 && this.windowWidth < 1001){
    document.getElementById('listCategory').style.display = 'none';
    }
    this.inputBox.setAttribute('style', 'width: 320px ');
    if(this.windowWidth > 320 && this.windowWidth < 485){
      this.inputBox.setAttribute('style', 'width: 200px ');
    }
    if(this.windowWidth >= 485 && this.windowWidth < 665){
      this.inputBox.setAttribute('style', 'width: 220px ');
    }
  }
// this method for search box is close the base of the text
  focusOutInput(){
    if($('#inputSearch').hasClass('closeSearch')){

    }else{
      if(this.windowWidth > 320 && this.windowWidth < 1001 && (this.inputBox.value == '')){
        this.inputBox.setAttribute('style', 'width: 23px ');
        document.getElementById('listCategory').style.display = 'block';
      }
    }
  }

  redirectSubmenu(video, menu?) {
      this.selectedMenuItem = this.catName;
      const otherNavigationExtras: NavigationExtras = {
        queryParams: {
          menu_item: this.selectedMenuItem
        }
      };
      if (video.is_livechannel) {
        this.baseService.storeEPGValue(video.channel_slug);
        if(this.dialog?.openDialogs?.length) this.dialogRef?.close();
        if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
        if(this.contentService.socket){
          const deleteMsg = {
            "action": "delete_data"
          }
          this.contentService.socket.send(JSON.stringify(deleteMsg));
        }
        this.router.navigate(['/live', video.channel_slug], otherNavigationExtras);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        this.router.navigate(['/series', seriesSlug, contentSlug], otherNavigationExtras);
      } else if (video.is_collection) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            menu_item: this.selectedMenuItem,
            slug: menu.submenu_slug,
          },
        };
        this.router.navigate(['/collection', video.collection_slug], navigationExtras);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug], otherNavigationExtras);
      }
      this.closeSearch();
  }

  redirectToViewAll(submenu) {
    this.selectedMenuItem = this.catName;
    const otherNavigationExtras: NavigationExtras = {
      queryParams: {
        menu_item: this.selectedMenuItem
      }
    };
    if(submenu?.lists.length > 0 && submenu?.lists[0]?.is_collection == '1') {
      this.router.navigate(['/movies', submenu.submenu_slug], otherNavigationExtras);
    } else if (submenu?.lists.length > 0 && (submenu?.lists[0]?.is_series == '1' && submenu.submenu_slug != 'dizi')) {
      this.router.navigate(['/series', submenu?.lists[0]?.collection_slug], otherNavigationExtras);
    } else if (submenu?.lists.length > 0 && submenu?.lists[0]?.is_livechannel == '1') {
      let live_channel_slug = '';
      if(this.storageService.getLocalStore('epg_live_channel')) live_channel_slug = this.storageService.getLocalStore('epg_live_channel');
      else live_channel_slug = submenu?.lists[0]?.channel_slug || 'channels';
      this.baseService.storeEPGValue(live_channel_slug);
      if(this.dialog?.openDialogs?.length) this.dialogRef?.close();
      if(this.settingsService.epgEnabled || this.settingsService.smartChannelsEnabled)this.router.navigate(['/live/' + (live_channel_slug)], otherNavigationExtras);
      else this.router.navigate(['/live/channels'], otherNavigationExtras);
    } else if (submenu?.lists.length > 0 && (submenu?.lists[0]?.is_series == '1' && submenu.submenu_slug == 'dizi')) {
      this.router.navigate(['/tdc', submenu.submenu_slug], otherNavigationExtras);
    }
    this.closeSearch();
  }

  ceilData(number){
    return Math.ceil(number);
  }

  onWishlistClick(wishlist) {
    this.baseService.setPreviousSlugValue(wishlist);
    if(window.innerWidth < 769)this.closeDropdown();
    this.closeSearch();
  }

  activateCodeRedirect() {
    if(this.settingsService.activateYourTvFromTVCode) this.router.navigateByUrl('account/activate-tv');
    if(this.settingsService.activateYourTvFromWebCode) this.router.navigateByUrl('account/authenticate');
  }

  ngOnDestroy(): void {
    if(this.dialog?.openDialogs?.length) this.dialogRef?.close();
  }

}
