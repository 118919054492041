import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { BaseService, StorageService } from 'src/app/core/services';
import { LoggerService } from 'src/app/core/authentication/logger.service';

@Component({
  selector: 'app-hoopla',
  template: `<p>Hoopla</p>`,
  styles: [
  ]
})
export class HooplaComponent implements OnInit, OnDestroy {
  params:any = {};
  constructor(private route: ActivatedRoute, private baseService: BaseService,
              private storageService: StorageService, private loggerService : LoggerService) {
    this.baseService.loaderSource.next(true);
  }

  ngOnInit(): void {
    this.params = {};
    // this.route.params.subscribe(queryparams => {
    //   this.params.d_id = this.storageService.getLocalStore('d_id');
    //   this.params.g_id = this.storageService.getLocalStore('g_id');
    //   this.params.d_type = "web";
    //   this.params.region = this.storageService.getLocalStore('region') || 'int';
    //   this.params.locale = this.storageService.getLocalStore('locale') || 'en-US';
    //   this.params.universalKey = queryparams.id;
    //   this.doAutologin();
    // });
  }

  async doAutologin() {
    //await this.loggerService.hooplaLogin(this.params);
  }

  ngOnDestroy() {
    //this.baseService.loaderSource.next(false);
  }

}
