import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { wishlist } from 'src/app/shared/constants';
import { HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  public loadComplete;
  seekCount;
  removeFlagOn = true;
  constructor(private http: HttpClient, private uikitService: UikitService, private storageService: StorageService) { }

  addToWishlist(data: object): Observable<any> {
    const wishlistURL = environment.apiUrl + environment.version + wishlist;
    return this.http.post(wishlistURL, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  removeWishlist(paramsData: any): Observable<any> {
    const reqParamsData: any = paramsData.req;
    const queryParamsData: any = paramsData.query;
    const uId = this.storageService.getLocalStore('u_id');
    const reqObj: any ={ 
      d_type: queryParamsData.d_type,
      u_id: uId
    }
    const wishlistURL = environment.apiUrl + environment.version + wishlist + '/' + reqParamsData.content_type + '/' + reqParamsData.id;
    return this.http.post(wishlistURL, reqObj).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      })
    );
  }

  getWishlist(data: any): Observable<any> {
    const uId: string = data.u_id;
    const dType: string = data.d_type;
    const skip: string = data.skip;
    const take: string = data.take;
    const params: HttpParams = new HttpParams()
      .set('d_type', dType)
      .set('skip', skip)
      .set('take', take)
      .set('u_id', uId);
    const wishlistURL = environment.apiUrl + environment.version + wishlist;
    return this.http.get(wishlistURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      })
    );
  }

  removeSeriesWishList(paramsData) {
    const reqParamsData: any = paramsData.req;
    const queryParamsData: any = paramsData.query;
    const uId = this.storageService.getLocalStore('u_id');
    const reqObj: any ={ 
      d_type: queryParamsData.d_type,
      u_id: uId
    }
    const wishlistURL = environment.apiUrl + environment.version + wishlist + '/series/' + reqParamsData.id;
    return this.http.post(wishlistURL, reqObj).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }
}
