import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService, SettingsService, StorageService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(private router: Router,private baseService: BaseService,
              private storageService:StorageService, private settingsService: SettingsService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const uId = this.storageService.getLocalStore('u_id');
    if((environment.appName === 'filmbox' || environment.appName === 'film1') && (this.baseService.showAndroidApp || this.baseService.showIosApp) && !uId){
      this.router.navigate(['/auth/signup'])
    } else {
      return true;
    }
  }
  
}
