<div id="pagetitle">
    <h1 class="wdn-text-hidden">{{pageH1Tag}}</h1>
</div>
<app-confirm-card></app-confirm-card>
<div class="bodyParent" [ngClass]="{'show-card': showUpdateCard}" [hidden]="loading">
    <app-header [hidden]="!settingsService.showHeader"></app-header>
    <router-outlet></router-outlet>
    <div *ngIf="showHoverCardParentDiv" #hoverkey class="hover-card" [ngClass]="{'hover-scaling':scaling == 'scale', 'hover-scaling-back': scaling == 'none'}" (mouseleave)="onMouseLeave()">
        <div *ngIf="showHoverData">
            <div *ngIf="key !== 'livechannel_widget' && key !== 'group_collection_widget' &&  key !== 'top_in_region_widget'" class="slider-card">
                <div class="card-img-container" (click)="redirect(value,key,dataTitle)">
                    <img *ngIf="!showTitle" [src]="cardIMg" [ngClass]="{'card-img':orientation == 1,'card-img-landscape':orientation != 1}" class="card-img" />
                    <div *ngIf="showTitle">
                        <img [src]="cardIMg" class="card-img" />
                        <a class="txt-trunc card-title"> {{value?.title}} </a>
                    </div>
                    <div class="img-gradient" [ngClass]="{'img-gradient-none':scaling == 'none'}" ></div>
                </div>
                <div class="onhover-card" [ngClass]="{'onhover-card-ml-two':orientation == 1}">
                    <div class="meta-card" [ngStyle]="{'display': displayOnTitle ? 'grid': 'flex'}">
                        <p *ngIf="displayOnTitle" class="meta-title">{{value?.title}}</p>
                        <p [ngClass]="{'cont-watch-meta-card': key == 'continue_watching_widget'}" class="meta-data-truncate" [ngStyle]="{'margin': settingsService.displayOnTitle ? 'inherit': '0 0 20px 0'}">{{value.metaCard}}</p>
                    </div>
                </div>
                <mat-progress-bar *ngIf="key == 'continue_watching_widget'" [value]=[value.percentage]></mat-progress-bar>
                <div [ngClass]="{'cont-watch-action-card': key == 'continue_watching_widget'}" class="actions-card">
                    <span class="actions-card-container">
                        <span class="action-card-tooltip" *ngIf="!(guest)">
                            <img (click)="CallingPlayContentService(value, 'play')" class="cursor-pointer card-icon" src="assets/{{appName}}/img/card-play.svg">
                            <span class="action-card-tooltip-text margin-null">{{ playText || 'Play'}}</span>
                        </span>
                        <span class="action-card-tooltip" *ngIf="!value?.wishlist && !guest && !value.is_livechannel && !restrictedUser">
                            <img (click)="CallingPlayContentService(value, 'add')" class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_add.svg">
                            <span class="action-card-tooltip-text margin-null">{{ addWishListText || 'Add to wishlist'}}</span>
                        </span>
                        <span class="action-card-tooltip" *ngIf="value?.wishlist && !guest && !value.is_livechannel && !restrictedUser">
                            <img (click)="CallingPlayContentService(value, 'remove')" class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_remove.svg">
                            <span class="action-card-tooltip-text margin-null">{{ removeWishListText || 'Remove from watchlist'}}</span>
                        </span>
                        <span class="action-card-tooltip">
                            <img (click)="redirect(value,key,dataTitle)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/content_info.svg">
                            <span class="action-card-tooltip-text margin-null'">{{ seeInfoText || 'See info'}}</span>
                        </span>
                        <span class = "action-card-tooltip" *ngIf="!(guest) && key == 'continue_watching_widget' && !restrictedUser">
                            <img (click)="removeContinue(value.continue_id)" [ngClass]="preventClick ? 'no-pointer' : ''" class="cursor-pointer card-icon" src="assets/{{appName}}/img/remove-icon.svg">
                            <span class = "action-card-tooltip-text margin-left">{{ removeListText || 'Remove from this list'}}</span>
                        </span>
                    </span>
                    <span *ngIf="value?.is_series && !guest && key == 'continue_watching_widget'" class="card-duration">
                        <span *ngIf="value?.season_position">
                            {{seasonText}}{{value?.season_position}}&nbsp;-&nbsp;{{episodeText}}{{value?.position}}
                        </span>
                        <span *ngIf="value?.season_position == 0">
                        {{episodeText}}{{value?.position}}
                        </span>
                    </span>
                </div>
            </div>
        </div>-
    </div>
    <app-footer [hidden]="!settingsService.showFooter"></app-footer>
</div>
<div class="loader-align loader-center" *ngIf="loader == 'dizi'" [hidden]="!loading">
    <img class="loader-size" src="assets/{{appName}}/img/dizi-loader.svg" alt="loader">
</div>
<div class="loader-align loader-center" *ngIf="loader == 'tv2z'" [hidden]="!loading">
    <img class="loader-size" src="assets/img/tv2z.svg" alt="loader">
</div>
<div class="loader-align loader-center" *ngIf="loader == 'funroads'" [hidden]="!loading">
    <img class="loader-size" src="assets/{{appName}}/img/funroadsloader.svg" alt="loader">
</div>
<div class="loader-align" *ngIf="loader == 'film1' || loader == 'filmbox'" [hidden]="!loading">
    <ng-lottie (domLoaded)="domLoaded()" width="350px" height="100vh" [options]="options"></ng-lottie>
</div>