import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { settings } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';
import { StorageService } from './storage.service';
import { UikitService } from './uikit.service';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  pageList: Array<object> = [];
  guest: boolean;
  termCondCheck;
  constructor(private storageService: StorageService, private http: HttpClient, private configService: ConfigurationService,
              private uikitService: UikitService) { }

  pageDetailsFn(pages: Array<object>) {
    this.pageList = pages;
    this.termCondCheck = this.pageList?.filter((page:any) => 
      page.type === 'terms' || page.type === 'privacy'
    )?.length ? true : false;
  }

  getSettingsFooterData(): Observable<any> {
    const userId = this.storageService.getLocalStore('u_id');
    const deviceId = this.storageService.getLocalStore('d_id');
    const guestId = this.storageService.getLocalStore('g_id');
    let paramurl;
    let settingParams;
    let localeSetting = this.storageService.getLocalStore('locale');
    if (!localeSetting) {
      localeSetting = this.configService.defaultLanguage;
    }
    if (deviceId) {
      if (userId) {
        paramurl = '/' + deviceId + '/' + userId;
        settingParams = new HttpParams()
            .set('d_type', 'web')
            .set('logged_in', '1')
            .set('locale', localeSetting);
      } else if (this.storageService.getLocalStore('g_id')) {
        paramurl = '/' + deviceId + '/' + guestId;
        settingParams = new HttpParams()
            .set('d_type', 'web')
            .set('logged_in', '0')
            .set('locale', localeSetting);
      }
      else {
        this.storageService.removeAll();
        window.location.reload();
      }
    } else {
      settingParams = new HttpParams()
          .set('d_type', 'web')
          .set('logged_in', '0')
          .set('locale', localeSetting);
    }
    let settingsUrl = environment.apiUrl + environment.version + settings;
    if (paramurl) {
      settingsUrl = settingsUrl + paramurl;
    }
    return this.http.get(settingsUrl , { params: settingParams }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.pageList = respData.pages;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error?.error?.error?.code !== 1002) {
            this.uikitService.notifyError(error);
          }
          return error;
        })
    );
  }

}
