import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { A_PAYWALL_BG } from 'src/app/shared/constants/assets.constants';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';
import { DOCUMENT } from '@angular/common';
import { StripeElementsOptions } from '@stripe/stripe-js';
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  configuration;
  region;
  operatorLogin;
  locales;
  displayLandingLocale;
  availableLocale;
  activeLocale;
  defaultLanguage = 'en-US';
  locale;
  localeIndex;
  localeActive;
  guest;
  localeKeys;
  stripeSupportedLocale = ['ar','bg','cs','da','de','el','en','en-GB','es','et','fi','fil','fr','fr-CA','he','hr','hu',
  'id','it','ja','ko','lt','lv','ms','mt','nb','nl','pl','pt-BR','pt','ro','ru','sk','sl','sv','th','tr','vi','zh'];
  stripeSupportedRegionLocale = ['en-GB','fr-CA','pt-BR','zh-HK','zh-TW'];
  stripeLocaleCode;
  language_code;
  plansLength;
  constructor(private settingsService: SettingsService, private router: Router,
              private storageService: StorageService, private http: HttpClient,
              @Inject(DOCUMENT) private document: Document
  ) {
  }

  async loacaleFn(configuration) {
    this.configuration = configuration;
    this.region = this.configuration.region.region_code;
    this.operatorLogin = this.configuration.region.operator_login;
    this.language_code = this.configuration.region.language_code;
    this.locale = this.storageService.getLocalStore('locale');
    this.availableLocale = this.storageService.getLocalStore('localeName');
    this.locales = configuration.locales || [];
    this.storageService.setLocalStore('region', this.configuration.region.region_code);
    this.displayLandingLocale = Number(this.settingsService.displayLandingLocale);
    if (this.locale) {
      let localeAvailable = this.locales.findIndex(x => x.lang_code == this.locale);
      let setLanguage = localeAvailable > -1 ? this.locale : this.defaultLanguage;
      this.defaultLanguage = setLanguage;
      await this.getLanguage(setLanguage);
      this.localeIndex = this.locales.findIndex(x => x.lang_code == this.defaultLanguage);
    } else {
      if (this.locales.length === 1) {
        this.defaultLanguage = this.locales[0].lang_code;
        this.availableLocale = this.locales[0].lang_name;
        await this.getLanguage(this.defaultLanguage);
        this.localeIndex = 0;
        this.storageService.setLocalStore('locale', this.defaultLanguage);
        this.setlocaleCookie(this.defaultLanguage);
      } else {
        if (this.displayLandingLocale === 0) {
          let localeAvailable = this.locales.findIndex(x => x.lang_code == this.configuration.region.locale);
          let langInCookie = localeAvailable > -1 ? this.configuration.region.locale : this.defaultLanguage;
          this.storageService.setLocalStore('locale', langInCookie);
          this.setlocaleCookie(langInCookie);
          this.storageService.setLocalStore('localeName', langInCookie);
          await this.getLanguage(langInCookie);
          this.locale = langInCookie;
          this.availableLocale = langInCookie;
          this.localeIndex = 0;
        } else {
          this.localeIndex = this.locales.findIndex(x => x.is_default == 1)
          this.locale = this.locales[this.localeIndex].lang_code;
          if (!this.storageService.getLocalStore('locale') && !this.settingsService.metroOnly) {
            this.router.navigate(['/app/language', 'select']);
          }
        }
      }
    }
  }

  async setBGImage(){
    const debugRegion = this.storageService.getLocalStore('debug_region');
    const payWallBgRegionCode = debugRegion.toUpperCase() || this.region.toUpperCase();
    this.settingsService.paywallBgImage
      = `${this.settingsService.imagePath}welcome_page_assets/PaywallBGWeb-${payWallBgRegionCode}.jpg`;
    // await new Promise((resolve,reject) => {
    //   this.http.get(this.settingsService.paywallBgImage, { responseType: 'blob' }).subscribe(
    //     (data) => {
    //       resolve(data);
    //     },
    //     (error) => { this.settingsService.paywallBgImage = A_PAYWALL_BG;
    //       resolve(error);
    //     }
    //   )
    // })
  }

  getLanguage = async (key) => {
    const selectedLocale = this.getLocalePath(key);
    this.setStripeLocale(key)
    this.document.documentElement.lang = key.substring(0, 2).toUpperCase();
    this.localeKeys = await new Promise((resolve, reject) =>
      this.http.get(selectedLocale.path).subscribe(
        (data) => { resolve(data) },
        (error) => { console.log(error); reject(error) }
      )
    )
  }

  setStripeLocale(key) {
    const regionLocale = key.split("-")?.[0];
    switch(true) {
      case(this.stripeSupportedRegionLocale.includes(key)):
        this.stripeLocaleCode = key;
        break;
      case(key === 'iw-IL'):
        this.stripeLocaleCode = 'he';
        break;
      case(this.stripeSupportedLocale.includes(regionLocale)):
        this.stripeLocaleCode = regionLocale;
        break;
      default:
        this.stripeLocaleCode = 'en';
        break;
    }
  }

  setlocaleCookie(locale: string){
    const cookieName = 'locale';
    const cookieValue = locale;
    document.cookie = cookieName +"=" + cookieValue + ";domain="+environment.cookieDomain+";path=/";
  }

  getLocalePath(key) {
    return this.locales.find((locale) => {
      if (locale['lang_code'] === key) {
        return locale;
      }
    })
  }

}
