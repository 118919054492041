import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  planDetail: any;
  freeTrialText: any;
  freeTrialDays: any;
  planAmount: any;
  planText: any;
  plansLength: any
  platformSettingsPlan: any;

  constructor(private storageService: StorageService) { }

  setPlanDetails(user_region){
    let plan: any = {};
    const platformRegion = this.storageService.getLocalStore('region');
    plan = this.platformSettingsPlan[user_region] || this.platformSettingsPlan[platformRegion];
    if(plan) {
      this.planDetail  = plan.plan_detail;
      if(plan.free_trial_days > 0) this.freeTrialText = plan.free_trial_text;
      this.freeTrialDays = plan.free_trial_days;
      this.planAmount = plan.amount;
      this.plansLength = plan['no_of_plans'];
      // to display plan info when single plan is present in home page detail page etc
      this.planText = plan.plan_text;
      localStorage.setItem('planInfo', this.planAmount);
    }
  }
}
